import axios, { AxiosInstance, isAxiosError } from "axios";

export type CreateJournalEntryData = {
  entryName: string;
  entryNumber: number;
  entryContent: string;
  initData: string;
};

export class BackendApi {
  private static instance: BackendApi;
  private axiosInstance: AxiosInstance;

  private constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_TG_BOT_API_URL,
    });
  }

  public static getInstance(): BackendApi {
    if (BackendApi.instance === null || BackendApi.instance === undefined) {
      BackendApi.instance = new BackendApi();
    }

    return BackendApi.instance;
  }

  public async createJournalEntry(data: CreateJournalEntryData) {
    try {
      const r = await this.axiosInstance.post("/createJournalEntry", data);
      return r.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response) {
          return error.response.data;
        }
        return error.response;
      }
    }
    return null;
  }
}
