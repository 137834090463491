import React from "react";
import ComponentContainer from "./ComponentContainer";

export default function ThankYou() {
  return (
    <ComponentContainer
      title="Thank you."
      subtitle="Your journal entry has been created!"
    >
      <></>
    </ComponentContainer>
  );
}
