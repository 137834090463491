import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NewJournalEntry from "./Components/NewJournalEntry";
import StartHere from "./Components/StartHere";
import ThankYou from "./Components/ThankYou";
import Layout from "./Layout";
import useKeyboardVisibility from "./useKeyboardVisibility";

function App() {
  useKeyboardVisibility();

  return (
    <Layout>
      <Router>
        <Routes>
          <Route path="/" index element={<StartHere />} />
          <Route path="/newjournalentry" index element={<NewJournalEntry />} />
          <Route path="/thankyou" index element={<ThankYou />} />
          <Route path="*" element={<StartHere />} />
        </Routes>
      </Router>
    </Layout>
  );
}

export default App;
