import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

type ComponentContainerProps = {
  title: string;
  subtitle: string;
  children: ReactNode;
};

export default function ComponentContainer({
  title,
  subtitle,
  children,
}: ComponentContainerProps) {
  const isNotHomePage = () => {
    return window.location.pathname !== "/";
  };

  return (
    <div className="component-container">
      <h2>{title}</h2>
      <p>{subtitle}</p>
      {isNotHomePage() && (
        <Link className="homepage-link mt-40px" to="/">
          Back Home
        </Link>
      )}
      {children}
    </div>
  );
}
