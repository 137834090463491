import React, { ReactNode } from "react";
import logo from "./logo.png";

type LayoutProps = {
  children: ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  return (
    <div id="body">
      <header>
        <img src={logo} alt="Football Entangled Logo" />
        <h2>Football Entangled</h2>
      </header>
      <main>{children}</main>
      <footer></footer>
    </div>
  );
}
