import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BackendApi } from "../backend";
import ComponentContainer from "./ComponentContainer";

export default function NewJournalEntry() {
  const { register, handleSubmit } = useForm();

  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submit = async (data: any) => {
    setIsLoading(true);

    data.initData = window.Telegram?.WebApp?.initData || "";
    const resp = await BackendApi.getInstance().createJournalEntry(data);

    setIsLoading(false);

    if (resp?.error) {
      setError(resp.error);
      return;
    }

    if (resp?.message) {
      navigate("/thankyou");
      setError(null);
      return;
    }
  };

  return (
    <ComponentContainer
      title="Create a new journal entry"
      subtitle="Make it exciting and real, Baller!"
    >
      {isLoading && <div className="loading-spinner"></div>}
      <form className="journal-entry-form" onSubmit={handleSubmit(submit)}>
        {error && error.length > 0 && (
          <label className="error-label">{error}</label>
        )}

        <label>Name (Title)</label>
        <input
          type="text"
          {...register("entryName", { required: true, maxLength: 255 })}
        />

        <label>Number</label>
        <input
          type="number"
          {...register("entryNumber", { required: true, valueAsNumber: true })}
        />

        <label>Content</label>
        <textarea
          rows={10}
          {...register("entryContent", { required: true })}
        ></textarea>

        <button type="submit" className="submit-button mt-10px">
          Create
        </button>
      </form>
    </ComponentContainer>
  );
}
