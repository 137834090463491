import { useEffect } from "react";

const useKeyboardVisibility = () => {
  useEffect(() => {
    const focusHandler = (event: FocusEvent) => {
      const target = event.target as HTMLElement;
      if (
        target.tagName.toLowerCase().trim() === "input" ||
        target.tagName.toLowerCase().trim() === "textarea" ||
        target.tagName.toLowerCase().trim() === "select"
      ) {
        document.body.classList.add("keyboard");
      }
    };

    const blurHandler = () => {
      document.body.classList.remove("keyboard");
    };

    // Using capture phase
    document.body.addEventListener("focus", focusHandler, true);
    document.body.addEventListener("blur", blurHandler, true);

    return () => {
      document.body.removeEventListener("focus", focusHandler, true);
      document.body.removeEventListener("blur", blurHandler, true);
    };
  }, []);
};

export default useKeyboardVisibility;
