import React from "react";
import { Link } from "react-router-dom";
import ComponentContainer from "./ComponentContainer";

export default function StartHere() {
  return (
    <ComponentContainer
      title="Welcome, Baller!"
      subtitle="Explore your options."
    >
      <Link className="start-here__link mt-40px" to="/newjournalentry">
        New Journal Entry
      </Link>
    </ComponentContainer>
  );
}
